import React from "react";
import type { Condition } from "~/types";

type Props = {
  text: string;
  labelColor: string /* primaryが職種、secondaryが業態、tertiaryがエリア */;
  handleAddCondition: (type: keyof Condition, value: string | string[]) => void;
  handleSubCondition: (type: keyof Condition, value: string | string[]) => void;
  type: keyof Condition;
  value: string | string[];
  selectedConditions: Condition;
};

const LabelSelectable: React.FC<Props> = (props) => {
  const isSelected = props.selectedConditions[props.type].includes(
    props.value as string
  );
  const [selected, setSelected] = React.useState(isSelected);

  const stateToggle = () => {
    const newSelected = !selected;
    setSelected(newSelected);
    if (newSelected) {
      props.handleAddCondition(props.type, props.value);
    } else {
      props.handleSubCondition(props.type, props.value);
    }
  };

  React.useEffect(() => {
    setSelected(isSelected);
  }, [props.selectedConditions, isSelected]);

  return (
    <div
      onClick={stateToggle}
      onKeyDown={stateToggle}
      role="button"
      tabIndex={0}
      className={`inline-block px-3 py-2.5 rounded-full text-xs leading-[1.35] font-bold md:py-1 md:text-sm md:leading-[1.8] border border-white ${
        selected ? "selected" : ""
      } ${
        props.labelColor
      } [&.primary]:bg-primary-light [&.primary]:text-primary hover:[&.primary]:border-primary [&.primary.selected]:bg-primary [&.primary.selected]:text-primary-light [&.secondary]:bg-secondary-light [&.secondary]:text-secondary hover:[&.secondary]:border-secondary [&.secondary.selected]:bg-secondary [&.secondary.selected]:text-secondary-light [&.tertiary]:bg-tertiary-light [&.tertiary]:text-tertiary hover:[&.tertiary]:border-tertiary [&.tertiary.selected]:bg-tertiary [&.tertiary.selected]:text-tertiary-light`}
    >
      {props.text}
    </div>
  );
};

export default LabelSelectable;
