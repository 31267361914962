import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const LabelList: React.FC<Props> = (props) => {
  return (
    <dl className="[&_dt]:mt-8 [&_dt]:mb-4 [&_dt]:font-bold [&_dt]:text-base [&_dt]:leading-normal [&_dd>*]:mr-3 [&_dd>*]:mb-3 md:[&_dt]:leading-normal md:[&_dd>*]:mr-2 md:[&_dd>*]:mb-2">
      {props.children}
    </dl>
  );
};

export default LabelList;
